<template>
  <div class="mainboxs">
    <div class="mainbox">

      <div class="academy_list">
        <div class="left">
          <div class="news_til">
            <div class="news_til_left_til">推荐学校</div>
            <div class="news_til_left_eng">RECOMMENDED SCHOOLS
              <div @click="goNext('/gxGather/abroad/academy')"
                style="display: flex;align-items: center;cursor: pointer;">院校库 <img
                  style="margin-left: 5px;width: 6px;height: 10px;" src="~@/assets/img/TechnicalCollege/48.png" alt="">
              </div>
            </div>
          </div>
          <div style="display: flex;margin-top: 15px;flex-wrap: wrap;">
            <div class="academy_item" @click="goNext(`/gxGathers/index?id=${item.id}`)"
              v-for="(item, index) in majorList" :key="index">
              <div class="school_img">
                <img :src="item.schoolImg" alt="">
                <div class="school_logo">
                  <img :src="item.schoolBadge" alt="">
                </div>
              </div>
              <div class="school_detail">
                <div class="school_address">
                  <img style="width: 10px;height: auto;margin-right: 5px;" src="~@/assets/img/TechnicalCollege/81.png"
                    alt="">
                  {{ item.areaProvinceName }} {{ item.areaCityName }}
                </div>
                <div class="school_name elp"> {{ item.schoolName }} </div>
                <div class="school_major elp"> <span>留学国家：</span> {{ item.countryNameList }} </div>
                <div class="lines"></div>
                <div class="school_msg">
                  <div>招生简章</div>
                  <div>常见问题</div>
                  <div>报考咨询</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="right">
          <div class="news_til">
            <div class="news_til_left_til">热门简章</div>
            <div class="news_til_left_eng">
              <div>HOT LIST</div>

            </div>
          </div>
          <div class="news_lists">
            <div @click="goNext(`/gxGathers/school/enrollmentGuide?enrollId=${item.id}&id=${item.schoolId}`)"
              v-for="(item, index) in newsList" :key="index">
              <div class="elp">{{ item.name }}</div>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
  components: {},
  props: {
    whichSchoolId: {
      type: String,
    },
    schoolName: {
      type: String,
    },

  },
  data() {
    return {
      whichNews: 1,
      sImg: '',
      link: '',
      majorList: [],
      newsList: [],
      search: {
        pageSize: 20,
        pageNum: 1,
        projectTypeId: '',
        projectCategoryId: ''
      },
    };
  },
  created() {
    this.projectType = localStorage.getItem('projectType');
    this.search.projectTypeId = localStorage.getItem('projectTypeId');
    this.search.projectCategoryId = localStorage.getItem('projectCategoryId');

    this.getStudyAbroadSchoolPage()
    know.selectBriefsPcAbroadAllPage({ ...this.search }).then((res) => {
      this.newsList = res.rows
    });
  },
  mounted() {

  },
  methods: {
    chooseMajor(i) {
      this.whichNews = i
    },
    getStudyAbroadSchoolPage() {
      know.getStudyAbroadSchoolPage({
        pageNum: 1,
        pageSize: 6,
        projectCategoryId: this.search.projectCategoryId,
      })
        .then((res) => {
          this.majorList = res.rows
        });
    },

    goPointLink() {
      if (this.link) {
        window.open(this.link, '_blank');

      }
    }

  },
};
</script>

<style lang="less" scoped>
.mainboxs {
  background: #F5F7F9;
  padding: 20px;
  padding-bottom: 0;
  padding-top: 0;
}

.mainbox {
  width: 1180px;
  // background: #FFFFFF;
  border-radius: 3px 3px 3px 3px;
  margin: 0 auto;
  padding-top: 15px;


  .news_til {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 18px;

    .news_til_left {
      display: flex;
      align-items: center;
      width: 196px;
      height: 42px;
      background: #36487A;
      background: url('~@/assets/img/TechnicalCollege/59.png');
      background-size: 100% 100%;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
      font-weight: normal;
      font-size: 20px;
      color: #FFFFFF;
      padding-left: 18px;
    }

    .news_til_right {
      cursor: pointer;
      font-family: PingFangSC-Regular, PingFangSC-Regular;
      font-weight: normal;
      font-size: 14px;
      color: #36487A;
      display: flex;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
    }
  }

  .academy_list {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .left {
      width: 746px;

      .news_til {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-right: 0;

        .news_til_left_til {
          font-family: PingFangSC-Medium, PingFangSC-Medium;
          font-weight: normal;
          font-size: 20px;
          color: #36487A;
          font-weight: bold;
          flex-shrink: 0;
        }

        .news_til_left_eng {
          display: flex;
          justify-content: space-between;
          margin-left: 5px;
          width: 100%;
          font-family: Helvetica, Helvetica;
          font-weight: normal;
          font-size: 14px;
          color: rgba(54, 72, 122, 0.5);
          border-bottom: 1px solid rgba(154, 163, 188, 0.5);
        }
      }

      .academy_item {
        width: 242px;
        background: #FFFFFF;
        box-shadow: 0px 3px 11px 1px rgba(7, 15, 32, 0.08);
        border-radius: 4px 4px 4px 4px;
        margin-right: 10px;
        cursor: pointer;
        transition: transform 0.6s ease;
        margin-bottom: 10px;


        .school_img {
          width: 242px;
          height: 114px;
          position: relative;
          border-radius: 4px;

          img {
            border-radius: 4px 4px 0 0;

          }


          .school_logo {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            left: 10px;
            bottom: -30px;
            position: absolute;

            img {
              border-radius: 50%;


            }
          }
        }

        .school_detail {
          padding: 16px;
          padding-top: 10px;

          .school_address {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 12px;
            color: #666666;
          }

          .school_name {

            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            margin-top: 10px;
          }

          .school_major {
            width: 200px;

            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 12px;
            color: #333333;
            margin-top: 12px;

            span {

              font-family: Microsoft YaHei, Microsoft YaHei;
              font-weight: bold;
              font-size: 12px;
              color: #666666;
            }
          }

          .lines {
            width: 100%;
            height: 0px;
            border: 1px solid #F7F7F7;
            margin: 14px 0;
          }

          .school_msg {
            display: flex;
            justify-content: space-between;

            >div {
              width: 66px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;

              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: normal;
              font-size: 12px;
            }

            >div:nth-child(1) {
              background: url('~@/assets/img/TechnicalCollege/53.png');
              background-size: 100% 100%;
              color: #3D6CC8;

            }

            >div:nth-child(2) {
              background: url('~@/assets/img/TechnicalCollege/54.png');
              background-size: 100% 100%;
              color: #DF932F;

            }

            >div:nth-child(3) {
              background: url('~@/assets/img/TechnicalCollege/55.png');
              background-size: 100% 100%;
              color: #BC3535;

            }
          }
        }

        &:hover {
          transform: scale(1.1)
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      .academy_items {
        width: 242px;
        height: 62px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        padding: 0 7px;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;

        .s_name {
          margin-left: 10px;

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 14px;
          color: #444444;
        }

        &:hover {
          border: 1px solid #36487A;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }







    }

    .right {
      width: 376px;

      .news_til {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-right: 0;

        .news_til_left_til {
          font-family: PingFangSC-Medium, PingFangSC-Medium;
          font-weight: normal;
          font-size: 20px;
          color: #36487A;
          font-weight: bold;
          flex-shrink: 0;
        }

        .news_til_left_eng {
          display: flex;
          justify-content: space-between;
          margin-left: 5px;
          width: 100%;
          font-family: Helvetica, Helvetica;
          font-weight: normal;
          font-size: 14px;
          color: rgba(54, 72, 122, 0.5);
          border-bottom: 1px solid rgba(154, 163, 188, 0.5);
        }
      }

      .news_lists {
        width: 376px;
        height: 568px;
        overflow: hidden;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #FFFFFF;
        margin-top: 14px;
        padding: 18px;

        >div {
          display: flex;
          align-items: center;

          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: normal;
          font-size: 14px;
          color: #444444;
          margin-bottom: 10px;
          cursor: pointer;

          >div {
            width: 300px;
          }
        }

        >div:hover {
          color: #BD1212;
        }
      }
    }
  }

}

img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>